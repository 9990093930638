import { BTCObject } from "./Types";

const BTCDetail = ({data}: {data:BTCObject | {id:string,}}) => {
    if('startsAt' in data ) {
        return (
        <div>
            <h2> Valgt trafikalt grunnkurs</h2>
            <div>Starttidspunkt: {data.startsAt}</div>
            <div>Adresse: {data.pickupLocation.address}</div>
            <div>Beskrivelse: {data.lesson.descriptionNorwegian}</div>
            <div>Pris: {data.lesson.price}</div>
        </div>
        );
    }
    return ( <div> Ikke valgt trafikalt grunnkurs</div>) 
        
}

export default BTCDetail;