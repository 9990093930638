import { ChangeEvent, FormEvent, useReducer, useState } from 'react';
import axios from 'axios';

const Personlia =() => {

  const formReducer = (state:any, event:any) => {
      return {
        ...state,
        [event.name]: event.value
      }
    }

  const [formData, setFormData] = useReducer(formReducer, {});
  const [submitting, setSubmitting] = useState(false);

  
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLSelectElement>) => {
    const isCheckbox = event.target.type === 'checkbox';
    setFormData({
      name: event.target.name,
      value: isCheckbox ? event.target.checked : event.target.value,
    });
  }

  const sendVerificationCode = async () =>{
    const url = "https://api.staging.koerapp.com/auth/code/".concat("98286858")  
    const res = await axios.get(url);
    console.log(res.data)
  }

  return (
    <div>
      { submitting &&
      <ul >
      {Object.entries(formData).map(([name, value]) => (
        <li key={name}><strong>{name}</strong>:{String(value)}</li>
      ))}
    </ul>}
    
      <form onSubmit={handleSubmit}>
      <fieldset>
        <label>
          <p>Navn</p>
          <input name='navn' required placeholder='For- og etternavn' onChange={handleChange} />
        </label>
        <label>
          <p>Telefonnummer</p>
          <input name='phonenumber' required type='tel' placeholder='8 siffer' maxLength={8} onChange={handleChange} />
          <div> <button onClick={sendVerificationCode}>Verifiser</button></div>
        </label>
        <label>
          <p>Personnummer</p>
          <input name='personalnumber' required type='tel' placeholder='11 siffer' maxLength={11} onChange={handleChange} />
        </label>
        <label>
          <p>Girtype</p>
          <select name="transmission" required onChange={handleChange}>
              <option value="">--Velg girtype--</option>
              <option value="automatic">Automatisk</option>
              <option value="manual">Manuell</option>
          </select>
        </label>
        <label>
          <p>Over 25år?</p>
          <input type="checkbox" required name="over-25years" onChange={handleChange} />
        </label>
      </fieldset>
      <button type='submit'> Til betaling</button>
    </form>
    </div>
  )
}

export default Personlia;