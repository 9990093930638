import './App.css';
import BTCList from './List';
import BTCDetail from './BTCDetail';
import Personlia from './Personlia';
import { BTCObject } from './Types';
import { useState } from 'react';

function App() {
  const [btcObject, setBtcObject] = useState<BTCObject | { id:string}>({ id:"test"})
  
  const chooseBTC = (btc:BTCObject) => {
    console.log(btc)
    setBtcObject(btc)
  };
  //data={btcObject}
  return (
    <div>
      <BTCList chooseBTC={chooseBTC}/>
      <BTCDetail data={btcObject}/>  
      <Personlia />
    </div>
  );
}

export default App;
