import { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns"
import { BTCObject } from "./Types";

const BTCList = ({chooseBTC}: {chooseBTC:(btc:BTCObject) => void }) => {
    const cityParam:string = new URLSearchParams(window.location.search).get('city')?.toString()!
    const url:string | undefined = process.env.REACT_APP_API_URL!;
    const[lessonData, setLessonData] = useState(Array<BTCObject>);

    const[showedLessons, setShowedLessons] = useState(Array<BTCObject>);

    useEffect(() => {
        getLessonData()
        setShowedLessons(lessonData)
      }, []);

    
    useEffect(() => {
        if(cityParam !== "") {
            handleChooseCity(cityParam)!
        }
    },[]) 

    function handleChooseCity(city: string) {
        if(city === undefined) {
            city = ""
        }

        setShowedLessons(lessonData.filter((lesson) => 
            lesson.pickupLocation.address.includes(city)));
    }
  
    const getLessonData = async () =>{
      const lessons: Array<BTCObject> = await axios.get(url?.concat('/public/lessons-btc')).then(res => res.data.bookedLessons);
      setLessonData(lessons);
    };

    return (
        <div>
            <div> 
                <button onClick={() => handleChooseCity("Oslo")}> Oslo</button>
                <button onClick={() => handleChooseCity("Lillestrøm")}> Lillestrøm</button>
                <button onClick={() => handleChooseCity("Tromsø")}>Tromsø</button>
                <button onClick={() => handleChooseCity("")}> Alle kurs</button>
            </div>
        <table id="table">
            <tbody style={{width: "100%"}}>
                <tr>
                    <th style={{ textAlign: "left", backgroundColor: "yellow", width: "10%"}}>Dato</th>
                    <th style={{ textAlign: "left" , backgroundColor: "green", width: "20%"}}>Tid</th>
                    <th style={{ textAlign: "left" , backgroundColor: "green", width: "40%"}}>Sted</th>
                    <th style={{ textAlign: "left" , backgroundColor: "green", width: "10%"}}>Pris</th>
                    <th style={{ textAlign: "left" , backgroundColor: "green", width: "15%"}}> Ledige Plasser</th>
                    <th style={{ textAlign: "left" , backgroundColor: "green"}}> Velg</th>
                </tr>
            {
                showedLessons.map( (btc) => {
                    const studentCount = btc.expectedNumberOfStudents - btc.studentRefs.filter(({cancelledAt}:any) => cancelledAt == null).length;
                    const startDate = new Date(btc.startsAt); 
                    return (
                            <tr key={btc.id}>
                                <td>{format(startDate, "dd/MM/yyyy")} </td>
                                <td>{format(startDate, "hh:mm")}</td> 
                                <td>{btc.pickupLocation.address}</td> 
                                <td>{btc.lesson.price}</td>
                                <td text-align="center">{ (studentCount <= 0) ? "Fullt" : studentCount }</td> 
                                <td><button onClick={() => chooseBTC(btc)}> Velg</button></td>
                            </tr>
                    )
                })   
            }
            </tbody>
        </table>
        </div>
    );
}

export default BTCList;